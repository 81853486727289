import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Socials from '../modules/socials'
import WknSwiper from '../modules/wkn-swiper';
import Marquee from '../animations/marquee';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Homepage extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      hero : viewStorage.current.querySelector('section.hero'),
      social : viewStorage.current.querySelector('section.social'),
      swiperProducts : viewStorage.current.querySelector('.block-products__slider .swiper'),
      swiperEngagements : viewStorage.current.querySelector('.engagements__slider .swiper'),
      marqueeImgFull : viewStorage.current.querySelector('.img-full .animated'),
      imgGroup : viewStorage.current.querySelector('.txt-3img__image'),
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Homepage`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { hero, social, swiperProducts, swiperEngagements, marqueeImgFull, imgGroup } = this.DOM
    hero && this.initHero()
    social && this.initSocial()
    swiperProducts && this.initSwiperProducts()
    swiperEngagements && this.initSwiperEngagements()
    marqueeImgFull && this.initMarquee()
    imgGroup && this.initImgGroup()

  }

  initHero() {
    const { hero } = this.DOM

    // Prllx
    const cup = hero.querySelector('.cup')
    const h1 = hero.querySelector('h1')
    const linesContainer = hero.querySelector('.lines')

    this.heroST = ScrollTrigger.create({
      trigger: hero,
      start: 'bottom bottom',
      end : 'bottom top',
      onUpdate: self => this.heroPrllx && this.heroPrllx.progress(self.progress),
      onLeave: () => this.heroTL && this.heroTL.pause(),
      onEnterBack: () => this.heroTL && this.heroTL.resume()
    })

    this.heroPrllx = gsap.timeline({
      paused: true,
      overwrite: true,
      defaults : {
        duration: 1,
        ease: 'linear.none'
      }
    })

    this.heroPrllx.fromTo(cup, { yPercent: 0 }, { yPercent: 50 }, 0)
    this.heroPrllx.fromTo(h1, { yPercent: 0 }, { yPercent: 200 }, 0)

    // Anim lines
    const lines = linesContainer.querySelectorAll('span')
    const lineheight = lines[0].offsetHeight

    // CurrentIndex
    const currentIndexWrapper = gsap.utils.wrap(0, lines.length)
    let currentIndex = currentIndexWrapper(0)
    const updateCurrentLine = () => {
      lines[currentIndex].classList.remove('--active')
      currentIndex = currentIndexWrapper(currentIndex + 1)
      lines[currentIndex].classList.add('--active')
    }

    // Init Lines
    gsap.set(lines, { y : (index) => index * lineheight })

    this.heroTL = gsap.timeline({
      defaults : {
        duration: 1.6,
        ease: 'expo.inOut'
      },
      delay: 4
    })

    this.heroTL.to(lines, { 
      y : () => `-=${lineheight}`,
      modifiers: {
        y: gsap.utils.unitize(gsap.utils.wrap(-lineheight, lineheight * (lines.length - 1)))
      },
      repeat: -1,
      repeatDelay: 1.4,
      repeatRefresh: true,
      onStart: updateCurrentLine,
      onRepeat: updateCurrentLine
    })

  }

  initSocial() {

    const { social } = this.DOM
    this.social = new Socials(social)

  }

  initSwiperProducts() {

    const { swiperProducts } = this.DOM
    this.swiperProducts = new WknSwiper(swiperProducts, { 
      slidesPerView: 1,
      spaceBetween: 12,
      breakpoints: {
        550: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        }
      }
    })
  }

  initSwiperEngagements() {

    const { swiperEngagements } = this.DOM
    this.swiperEngagements = new WknSwiper(swiperEngagements, { 
      slidesPerView: 1,
      spaceBetween: 15,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
          allowTouchMove: false,
          touchRatio: 0,
        }
      }
    })
  }

  initMarquee() {

    const { marqueeImgFull } = this.DOM
    this.marqueeImgFull = new Marquee(marqueeImgFull)

  }

  initImgGroup() {

    const { imgGroup } = this.DOM

    let mm = gsap.matchMedia();

    this.imgGroupST = ScrollTrigger.create({
      trigger: imgGroup,
      top: 'top top',
      onLeaveBack: () => this.imgGroup.pause(0)
    })

    this.imgGroup = gsap.timeline({
      paused: true,
      defaults: { ease: 'power4.out', duration: 1.2 },
      scrollTrigger : {
        trigger: imgGroup,
        start: 'center center+=33%',
        end: 'bottom top',
        onEnter: () => this.imgGroup.invalidate().restart()
      }
    })

    mm.add("(min-width: 768px)", () => {
      this.imgGroup.fromTo(imgGroup.querySelectorAll('.visual__container .visual'), { x : () => window.innerWidth * 0.8, yPercent: (index) => gsap.utils.random(0, 100), rotate: (index) => gsap.utils.random(-20, 20) }, { x: 0, yPercent:0, rotate: (index) => gsap.utils.random(-20, 20), duration: (index) => 1.2 + index * 0.3 })
    });
    
    mm.add("(max-width: 767px)", () => {
      this.imgGroup.fromTo(imgGroup.querySelectorAll('.visual__container .visual'), { y : () => window.innerHeight * 0.5 }, { y: 0, stagger: 0.1 })
    });

  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { hero, social, swiperProducts, swiperEngagements, marqueeImgFull, imgGroup, imgGroupST } = this
    if (hero) {
      this.heroST.kill(true)
      this.heroPrllx.kill(true)
      this.heroTL.kill(true)
    }
    social && social.destroy()
    swiperProducts && swiperProducts.destroy()
    swiperEngagements && swiperEngagements.destroy()
    marqueeImgFull && marqueeImgFull.destroy()
    if (imgGroup) {
      imgGroupST.kill()
      imgGroup.kill(true)
    }
  }
}
